//colors by name
$color-light-blue: #0062ff;
$color-light-gray: #c1c1c1;
$color-gray: #333333;
$color-gray-mid: #626262;
$color-red: #aa321e;
$color-dark: #2b2e33;
$color-light: #fff;
$color-light-yellow: #ffffd6;
$color-light-green: #def98b;

//colors by function
$color-text: #16171a;
$color-bg: $color-dark;
$color-link: $color-gray;
$color-link-hover: $color-light-blue;
$color-border: $color-light-gray;
$color-text-selection: $color-light-blue;
$color-highlight: $color-light-yellow;

$color-notice: $color-light-yellow;
$color-error: $color-red;
$color-success: $color-light-green;

$color-link: #0062ff;
$color-link-hover: $color-link;

$color-btn-default-border: $color-light-blue;
$color-btn-default-background: $color-light-blue;
$color-btn-default-text: #ffffff;

$color-btn-default-border-hover: #174999;
$color-btn-default-background-hover: #174999;
$color-btn-default-text-hover: #ffffff;

$color-btn-default-inverse-border: #ffffff;
$color-btn-default-inverse-background: #ffffff;
$color-btn-default-inverse-text: #000000;

$color-btn-default-inverse-border-hover: #ffffff;
$color-btn-default-inverse-background-hover: #000000;
$color-btn-default-inverse-text-hover: #ffffff;
