.steps {
    position: relative;
    overflow: hidden;

    .step {
        position: absolute;
        top: 0;
        width: 100%;
        opacity: 0;
        transform: translateY(-120px);
        visibility: hidden;
        transition: opacity 0.2s, transform 0.2s, visibility 0s 0.2s;

        &.active {
            position: static;
            opacity: 1;
            transform: translateY(0);
            transition-delay: 0s;
            visibility: visible;
        }

        &.active ~ .step {
            transform: translateY(120px);
        }
    }
}
