.teaserList {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 -5px;

    @include mq(sm) {
        margin: -7px -6px -7px;
    }
}

.teaser {
    display: flex;
    flex: 0 0 100%;
    min-height: 344px;
    padding: 5px 0;
    width: 100%;
    font-weight: font-weight(light);

    @each $breakpoint in map-keys($mq-breakpoints) {
        $teaser-total-columns: 12;
        $teaser-columns: 4 6 12;

        @if $breakpoint != xs {
            @include mq($breakpoint) {

                @each $size in $teaser-columns {
                    &-#{$breakpoint}-#{$size} {
                        $percentage: $size / $teaser-total-columns * 100%;
                        flex-basis: $percentage;
                        max-width: $percentage;
                    }
                }
            }
        }
    }

    &-break {
        display: block;
        flex: none;
        min-height: 0;
        padding: 0;
        width: 100% !important;
    }

    &_inner {
        position: relative;
        display: flex;
        width: 100%;
    }

    &_inner:hover &_copy {
        padding-bottom: 10px;
    }

    &_inner:hover &_copy &_btn span {
        padding-bottom: 50px;
        border-bottom: 5px solid $color-light-blue;
    }

    &_background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: hidden;

        &Media {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: no-repeat 50% 50%;
            background-size: cover;
            transform: scale(1);
            transition: transform 0.2s;
        }
    }

    &_copy {
        position: relative;
        z-index: 1;
        align-self: flex-end;
        width: 100%;
        background: #fff;
        padding: 20px 15px;

        -webkit-transition: all 0.5s;
        transition: all 0.5s;
    }

    &_heading {
        margin: 0 0 16px;
        font-weight: font-weight(regular);
        line-height: line-height(40px, 35px);
        text-transform: uppercase;
    }

    &_btn {
        text-transform: uppercase;

        span {
            border-bottom-width: 5px;

            -webkit-transition: all 0.5s;
            transition: all 0.5s;
        }
    }

    &_background:hover &_backgroundMedia,
    &_copy:hover + &_background &_backgroundMedia {
        //transform: scale(1.1);
    }

    @include mq(sm) {
        padding: 7px 6px;
    }

    @include mq(md) {
        &-md-2 &_copy {
            max-width: none;
        }

        &_heading {
            font-size: font-size(26px);
        }
    }

    //@include mq(lg) {
    //    &_heading {
    //        font-size: font-size(35px);
    //    }
    //}
}

$teasers: (
    //covid-19-bmw-bank,  // custom, see below
    configurator,       // xs: 767x334, sm: 478x330, md: 511x330
    brochure,           // xs: 767x334, sm: 478x330, md: 511x330
    test-ride,          // xs: 767x334, sm: 478x330, md: 511x330
    financial-services, // xs: 767x334, sm: 478x330, md: 511x330
    used-bikes,         // xs: 767x334, sm: 478x330, md: 511x330
    federclub,          // xs: 767x334, sm: 478x330, md: 511x330 + custom, see below
    events,             // xs: 767x334, sm: 478x330, md: 511x330
    dealer-locator,     // xs: 767x334, sm: 478x330, md: 511x330
    services            // xs: 767x334, sm: 478x330, md: 511x330
);

.teaser_backgroundMedia {

    @each $teaser in $teasers {
        #teaser-#{$teaser} & {
            background-image: url("../images/teaser/#{$teaser}-xs.jpg");
            //background-image: url("../images/teaser/#{$teaser}.jpg");
        }
    }

    @each $breakpoint in sm md {
        @include mq($breakpoint) {
            @each $teaser in $teasers {
                #teaser-#{$teaser} & {
                    background-image: url("../images/teaser/#{$teaser}-#{$breakpoint}.jpg");
                }
            }
        }
    }
}

#teaser-covid-19-bmw-bank {
    min-height: 500px;

    .teaser_backgroundMedia {
        background-image: url("../images/teaser/covid-19-bmw-bank.jpg");
    }
}

#teaser-federclub {
    .teaser_backgroundMedia {
        @include mq($until: 479px) {
            background-image: url("../images/teaser/federclub-xxs.jpg");
        }
    }
}
