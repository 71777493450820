@font-face{
    font-family:"BMWMotorradW01-Regular";
    src:url("/assets/fonts/motorradtypeweb/BMWMotorradW01-Regular.eot?#iefix");
    src:url("/assets/fonts/motorradtypeweb/BMWMotorradW01-Regular.eot?#iefix") format("eot"),url("/assets/fonts/motorradtypeweb/BMWMotorradW01-Regular.woff2") format("woff2"),url("/assets/fonts/motorradtypeweb/BMWMotorradW01-Regular.woff") format("woff"),url("/assets/fonts/motorradtypeweb/BMWMotorradW01-Regular.ttf") format("truetype");
}

@font-face{
    font-family:"BMWMotorradW01-Bold";
    src:url("/assets/fonts/motorradtypeweb/BMWMotorradW01-Bold.eot?#iefix");
    src:url("/assets/fonts/motorradtypeweb/BMWMotorradW01-Bold.eot?#iefix") format("eot"),url("/assets/fonts/motorradtypeweb/BMWMotorradW01-Bold.woff2") format("woff2"),url("/assets/fonts/motorradtypeweb/BMWMotorradW01-Bold.woff") format("woff"),url("/assets/fonts/motorradtypeweb/BMWMotorradW01-Bold.ttf") format("truetype");
}

$font-family-base: BMWMotorradW01-Regular, "Lucida Sans Unicode", "Lucida Grande", "Noto Sans";
$font-family-h: BMWMotorradW01-Bold, Tahoma, "Noto Sans";
$font-family-sans: $font-family-base;


$font-weights: (
    thin: 100,
    extralight: 200,
    extra-light: 200,
    light: 300,
    regular: 400,
    normal: 400,
    medium: 500,
    semibold: 600,
    semi-bold: 600,
    bold: 700,
    extrabold: 800,
    extra-bold: 800,
    black: 900
);


h1, .h1 {
    font-family: $font-family-h;
    &.light {
        font-weight: font-weight(light);
    }
}

h2, .h2 {
    font-family: $font-family-h;
    &.light {
        font-weight: font-weight(light);
    }
}

h3, .h3 {
    font-family: $font-family-h;
    &.light {
        font-weight: font-weight(light);
    }
}

h4, .h4 {
    font-family: $font-family-h;
    &.light {
        font-weight: font-weight(light);
    }
}

h5, .h5 {
    font-family: $font-family-h;
    &.light {
        font-weight: font-weight(light);
    }
}

h6, .h6 {
    font-family: $font-family-h;
    &.light {
        font-weight: font-weight(light);
    }
}

// //font dimensions
$font-size-default: 18px;
$font-lheight-default: 24px;

$font-sizes: (
    xl: ceil($font-size-default * 2),
    l: ceil($font-size-default * 1.5),
    m: $font-size-default,
    s: ceil($font-size-default * 0.8),
    xs: ceil($font-size-default * 0.5)
);

//font dimensions by function
$font-sizes-headings: (
    1: 36px,
    2: 26px,
    3: 22px,
    4: 22px,
    5: 18px,
    6: 18px
);

$font-lheights-headings: ();

$font-sizes-headings-sm: (
    1: 36px, //60px,
    2: 26px, //40px,
    3: 22px, //28px,
    4: 22px, //22px,
    5: 18px, //20px,
    6: 18px  //18px
);

$font-lheights-headings-sm: ();

$font-size-h: ceil($font-size-default * 2);
$font-size-text: $font-size-default;

$font-lheight-h: 1.2;

// @import "../../fonts/bmw_default/bmw_default";
