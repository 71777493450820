.contactTeaser {
    overflow: hidden;
    margin: 0 0 12px;

    &_heading {
        margin: 0;
        padding: 25px;
        background: $color-light;
        font-size: font-size(34px);
        line-height: line-height(40px, 35px);
        text-align: center;
        text-transform: uppercase;

        &-underline {
            //border-bottom: 7px solid #c1c1c1;
            //padding-bottom: 13px;
            //display: inline-block;
        }
    }

    &_content {
        position: relative;
        margin: 0;
        background: $color-light;
        color: $color-text;
        font-family: $font-family-sans;
    }

    &_contentHeader {
        border: {
            width: 12px 0;
            style: solid;
            color: $color-bg;
        }
        padding: 20px;

        > p {
            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &_contentInner {
        position: relative;
        transition: height 0.2s;
    }

    &_items {
        display: flex;
        flex-wrap: wrap;
        opacity: 0;
        transform: scale(#{(1 / 1.1)});
        transition: opacity 0.2s, transform 0.2s;

        &.active {
            opacity: 1;
            transform: scale(1);
            transition-delay: 0.05s;
        }

        &.deactivated {
            position: absolute;
        }
    }

    &_item {
        width: 100%;
        padding: 25px 20px 15px;
        -webkit-transition: all 0.5s;
        transition: all 0.5s;

        &Heading {
            font-family: $font-family-sans;
            font-size: font-size(18px);
            font-weight: font-weight(regular);
            line-height: line-height(30px, 21px);

            &Text {
                display: inline-block;
                color: $color-text;
                text-decoration: none;
                text-transform: uppercase;
            }

            .btn {
                width: 100%;

                span {
                    width: 100%;
                    font-size: font-size(14px);
                    -webkit-transition: all 0.5s;
                    transition: all 0.5s;

                    background-position: 100% 3px;
                }
            }
        }

        &:hover {
            padding-top: 13px;
            padding-bottom: 0;

            .contactTeaser_itemHeading .btn span {
                padding-bottom: 40px;
                border-bottom-color: $color-light-blue;
            }
        }
    }

    &_details {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        width: 100%;
        min-height: 100%;
        visibility: hidden;
        transform: translateY(100px);
        transition: opacity 0.2s, transform 0.2s, visibility 0s 0.2s;

        &.active {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
            transition: opacity 0.2s, transform 0.2s, visibility 0s;
            transition-delay: 0.05s;
        }

        &.activated {
            position: static;
        }
    }

    &_detail {
        position: absolute;
        top: 0;
        left: 0;
        min-height: 100%;
        padding: 35px 20px 20px;
        width: 100%;
        visibility: hidden;
        transition: visibility 0s 0.2s;
        text-transform: uppercase;

        &.active {
            visibility: visible;
            transition: visibility 0s;

            .activated > & {
                position: static;
            }
        }

        &Header {
            margin-bottom: 32px;
        }

        &Body {
            margin-bottom: 40px;

            h2 {
                font-size: font-size(16px);
                font-weight: 400;
            }

            p {
                font-size: font-size(16px);
                font-weight: 100;
            }
        }

        &Heading {
            margin-bottom: 0;
            font-family: $font-family-h;
            font-weight: font-weight(regular);
            line-height: line-height(50px, 40px);
            text-transform: uppercase;

            &-underline {
                border-bottom: 5px solid $color-light-gray;
                padding-bottom: 13px;
                display: inline-block;
            }
        }

        &SubHeading {
            margin-bottom: 0;
            font-weight: font-weight(regular);
        }

        &Close {
            position: absolute;
            top: 18px;
            right: 20px;
            display: block;
            height: 22px;
            padding: 4.5px;
            width: 22px !important;
            border: 1px solid $text-color;
            border-radius: 50%;
            font-size: 16px;
            line-height: 0;
            background-color: #fff;
        }

        &Cta {
            display: flex;
            justify-content: center;
            margin: 0 5px 5px 0;
            //width: 100%;

            &::after {
                content: "";
                display: inline-block;
                margin: 3px 0 0 10px;
                background-image: url('../images/btn/arrow-right-white.png');
                background-position: bottom center;
                background-repeat: no-repeat;
                width: 10px;
                height: 21px;
            }

            &-noicon {
                &::before,
                &::after {
                    display: none;
                }
            }

            &-email {
                &::before,
                &::after {
                    margin-top: 7px;
                    height: 14px;
                    width: 20px;
                    background-size: 100%;
                }

                &::before {
                    margin-left: 20px;
                }

                &::before,
                &::after {
                    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAUCAYAAACeXl35AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTExIDc5LjE1ODMyNSwgMjAxNS8wOS8xMC0wMToxMDoyMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTc0RjIyNjgwRTJEMTFFNkI4OUY4QzJEMjYxNURCRTUiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTc0RjIyNjkwRTJEMTFFNkI4OUY4QzJEMjYxNURCRTUiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5NzRGMjI2NjBFMkQxMUU2Qjg5RjhDMkQyNjE1REJFNSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5NzRGMjI2NzBFMkQxMUU2Qjg5RjhDMkQyNjE1REJFNSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PgyG8owAAAEhSURBVHja7JQxagJRFEXf1ymSRiwFS8HaIlhbZAXTZAcWdpZpsgMJuAMbV5DCDVhobWEbsBO7YKHF9365Ay+PTPjjRCHghQPyc989hUHnvV+ISFduk6VAmIA3cPTXy5GOJAgznsD6CrI1t88eLQw8gvEfysbcFC0cgQcjfgabEqINN/RmcIyEhRXomEIdTC+QTXmrtzp0eFHFA3gFVVN+AbsI0Y5dfVvl5iEryQ+Hc9Ayh00w+0U2Y0fftLj1LZIz8AX6ZsCBAdir3p5vznT73PCxwiwfoGHG2uHHgrTN3xq8yY1EfDdbkJrhhOi3lF1fVphlAmpGInybxI5IwX/5T9BTsh7fouPO1mIJ/Xd+HgJX5PgSYalU5Ma5C/+/8CTAAJvG5YDbfB5jAAAAAElFTkSuQmCC");
                }

            }

            &-back {
                &::after {
                    content: none;
                }

                &::before {
                    content: "";
                    display: inline-block;
                    margin: 5px 10px 0 0;
                    background: url('../images/btn/arrow-right-white.png') no-repeat bottom center;
                    width: 10px;
                    height: 21px;
                    transform: rotate(180deg);
                }
            }

        }

        &Button {
            display: flex;
            margin: 10px -5px -5px 0;
            color: $color-gray-mid;
            line-height: 1.5;
            font-size: smaller;
            font-weight: 100;

            .btn-small {
                margin-right: 0.5em;
                width: auto;
                padding: 0 0.2em 0.1em;
            }
        }

        &Note {
            font-size: smaller;
            color: $color-gray-mid;
            text-transform: none;
        }

        @include classed-headings() {
            margin-bottom: 0;
        }
    }

    @include mq(xs) {
        &_item {
            width: (100% / 2);
        }
    }

    @include mq(sm) {

        //&_content {}

        &_items {
            margin: {
                left: 0;
                right: 0;
            }
        }

        &_item {
            // width: (100% / 2);

            &Heading {
                font-size: font-size(20px);
            }
        }

        &_detail {
            padding: 35px;
        }
    }

    @include mq(md) {
        $breakpoint-size: mq-get-breakpoint-width(md);
        $heading-width-percentage: ($breakpoint-size / 3 + 48px) / $breakpoint-size * 100%;

        display: flex;

        &_heading {
            display: flex;
            justify-content: center;
            flex: 0 0 calc(33.333333% - 8px);
            align-items: center;
            margin: 0 12px 0 0;
            width: calc(33.333333% - 8px);
            font-size: font-size(42px);
        }

        &_content {
            flex: 1 1 0;
        }

        &_contentHeader {
            border-top: 0;
            padding: {
                right: 35px;
                left: 35px;
            }
        }

        &_items {
            margin: 0;
            padding: 0 20px;
        }

        &_item {
            padding: {
                right: 15px;
                left: 15px;
            }
            // width: (100% / 2);
        }

        &_detail {
            display: flex;
            flex-direction: column;
            padding: 35px;

            &Header,
            &Footer {
                flex: 0 0 auto;
            }

            &Body {
                flex: 1 0 auto;
                font-size: font-size(20px);
            }

            &Footer {
                position: absolute;
                bottom: 35px;
                right: 22px;
                display: flex;
                justify-content: flex-end;
                margin: 0 -5px -5px 0;
            }
        }
    }

    @include mq(lg) {
        $breakpoint-size: mq-get-breakpoint-width(lg);
        $heading-width-percentage: ($breakpoint-size / 3 + 48px) / $breakpoint-size * 100%;

        &_heading {
            display: flex;
            justify-content: center;
            flex: 0 0 calc(33.333333% - 8px);
            align-items: center;
            margin: 0 12px 0 0;
            width: calc(33.333333% - 8px);
        }

        &_item {
            // width: (100% / 2);

            &Heading {
                .btn {
                    span {
                        font-size: font-size(16px);
                    }
                }
            }
        }

        &_detail {
            padding: 35px;

            &Heading {
                font-size: font-size(30px);
            }

            &Body {
                h2,
                p {
                    font-size: font-size(20px);
                }
            }
        }
    }


    &_detail:not(.active) &_step {
        visibility: hidden !important;
        transition-delay: 0.2s !important;
    }

    //&_detail &_step p {
    //    margin: 0 0 5px !important;
    //}

    &_detail &_stepBtns {
        display: flex;
        //
    }

    &_detail &_stepBtns &_detailButton {
        margin-right: 0;
        flex-shrink: 0;
        width: calc(50% + 2.5px);

        @include mq(md) {
            width: auto;
        }
    }

    &_detail &_stepBtns &_detailCta {
        @include mq($until: md) {
            width: 100%;
        }
    }

}
