.btn {
    border: 2px solid;
    display: inline-block;
    padding: 2px 10px;
    width: 100%;
    background: none;
    border-radius: 0;
    cursor: pointer;
    font-family: $font-family-h;
    font-size: font-size(16px);
    font-weight: 400;
    line-height: line-height(28px, 16px);
    outline: none;
    text-align: center;
    text-decoration: none;
    transition: background-color 0.2s, border-color 0.2s, color 0.2s, opacity 0.2s;

    &-smallfont {
        font-size: font-size(14px);
        line-height: line-height(28px, 14px);
        text-transform: none;
    }

    &-naked {
        border: 0;
        margin: 0;
        padding: 0;
        background: transparent;
        color: inherit;
        line-height: inherit;
        text-align: inherit;
        width: 100% !important;

        span {
            border-bottom: 5px solid $color-light-gray;
            padding-bottom: 13px;
            display: inline-block;
            position: relative;
            width: 100%;

            background: url('../images/btn/arrow-right-gray.png') no-repeat 100% -1px;
            padding-right: 16px;
        }
    }

    &-default {
        border-color: $color-btn-default-border;
        background: $color-btn-default-background;
        color: $color-btn-default-text;

        &:hover {
            border-color: $color-btn-default-border-hover;
            background: $color-btn-default-background-hover;
            color: $color-btn-default-text-hover;
        }

        &.inverse {
            border-color: $color-btn-default-inverse-border;
            background: $color-btn-default-inverse-background;
            color: $color-btn-default-inverse-text;

            &:hover {
                border-color: $color-btn-default-inverse-border-hover;
                background: $color-btn-default-inverse-background-hover;
                color: $color-btn-default-inverse-text-hover;
            }
        }
    }

    @include mq(sm) {
        width: auto;
    }
}
