/* ==========================================================================

   Base Styles
   ====


   Some Basic Styles

   ```
   ```

   ========================================================================== */

*,
*::before,
*::after {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html,
button,
input,
select,
textarea {
    color: $color-text;
}

html,
body {
    line-height: 1.2;
}

html {
    height: 100%;
    overflow-y: scroll;
    font-size: percentage($font-size-default / 16px); //16px

    &.hidden {
        display: block !important;
    }

    &.embedded {
        height: auto;

        &,
        & body {
            overflow: hidden;
            width: 100vw;
        }
    }
}

body {
    //height: 1px;
    //min-height: 100%;
    margin: 0;
    padding: 16px 0;
    min-width: 320px;
    background: $color-bg;
    color: $color-text;
    font-family: $font-family-base;
}

body.cookies {
    background-color: $color-light !important;
    font-family: $font-family-base, Arial, Helvetica, sans-serif;
}

//Remove text-shadow in selection highlight: h5bp.com/i
//These selection declarations have to be separate.
//Customize the background color to match your design.

//::-moz-selection { // scss-lint:disable VendorPrefix
//    color: $color-text-selection;
//    text-shadow: none;
//}

//::selection {
//    color: $color-text-selection;
//    text-shadow: none;
//}

// Remove the gap between images and the bottom of their containers: h5bp.com/i/440

audio,
canvas,
iframe,
svg,
video,
img {
    vertical-align: middle;
}

// A better looking default horizontal rule

hr {
    display: block;
    height: 1px;
    margin: 1rem 0;
    padding: 0;
    border: 0;
    border-top: 1px solid $color-border;
}



// Remove default fieldset styles.

fieldset {
    margin: 0;
    padding: 0;
    border: 0;
}

// Allow only vertical resizing of textareas.

textarea {
    resize: vertical;
}

input::-moz-focus-inner {
    border: 0;
}

// Slow down autofilled input aspect

input:-webkit-autofill,
select:-webkit-autofill {
    &,
    &:active,
    &:focus,
    &:hover {
        transition-delay: 14400s;
        transition-property: background-color, color;
    }
}

/* ==========================================================================
   ##prevent 300ms delay in FF, Chrome and IE on touch devices
   ========================================================================== */

a[href], // scss-lint:disable QualifyingElement
button {
    touch-action: manipulation;
}

/* ==========================================================================
   ##Headings and Base

   1 to 6 heading

   ```
   ```
   ========================================================================== */

%heading-base {
    margin: 0 0 10px;
    //color: $color-text
    font-family: $font-family-h;
    font-weight: font-weight(bold);
}

@include classed-headings {
    @extend %heading-base;
}

$lheight-ident-selectors: ();

@each $font-head-num, $font-head-size in $font-sizes-headings {
    $selector: unquote("h#{$font-head-num}, .h#{$font-head-num}");

    #{$selector} {
        font-size: font-size($font-head-size);

        @if map-has-key($font-lheights-headings, $font-head-num) {
            line-height: line-height(map-get($font-lheights-headings, $font-head-num), $font-head-size);
        } @else {
            $lheight-ident-selectors: append($lheight-ident-selectors, $selector, comma);
        }
    }
}

@if length($lheight-ident-selectors) > 0 {
    #{$lheight-ident-selectors} {
        line-height: 1;
    }
}

@include mq(sm) {
    $lheight-ident-selectors: ();

    @each $font-head-num, $font-head-size in $font-sizes-headings-sm {
        $selector: unquote("h#{$font-head-num}, .h#{$font-head-num}");

        #{$selector} {
            font-size: font-size($font-head-size);

            @if map-has-key($font-lheights-headings-sm, $font-head-num) {
                line-height: line-height(map-get($font-lheights-headings-sm, $font-head-num), $font-head-size);
            } @else {
                $lheight-ident-selectors: append($lheight-ident-selectors, $selector, comma);
            }
        }
    }

    @if length($lheight-ident-selectors) > 0 {
        #{$lheight-ident-selectors} {
            line-height: 1;
        }
    }
}

p {
    margin: 10px 0;
    line-height: $font-lheight-h;

    @include mq(sm) {
        margin: 15px 0;
    }
}

a:not([class]) {
    color: $color-link;
    text-decoration: none;

    -webkit-transition: color 0.5s;
    transition: color 0.5s;

    &:hover {
        //set to important so that hover is hardly overwritten by other styles
        color: $color-link-hover !important; // scss-lint:disable ImportantRule
        text-decoration: underline;
    }
}

/* ==========================================================================
   ## CSS to JS Breakpoint Bridge
   ========================================================================== */

@if $mq-responsive {

    body::after,
    body::before {
        position: absolute;
        top: 0;
        left: 0;
        z-index: z-index(behind) - 2;
        display: block;
        visibility: hidden;
        overflow: hidden;
        width: 1px;
        height: 1px;
    }

    body::after {
        content: 'xs';

        @each $mq-key, $mq-value in $mq-breakpoints {
            @include mq($mq-key) {
                content: '#{$mq-key}';
            }
        }
    }

    body::before {
        content: '/#{map-to-JSON($mq-breakpoints)}/'; // scss-lint:disable NameFormat
    }

    //fallback
    head {
        font-family: '/#{map-to-JSON($mq-breakpoints)}/'; // scss-lint:disable NameFormat
    }
}


.container {
    max-width: 1600px !important;
    padding: 0;
    width: auto;

    &.no-padding {
        padding: {
            right: 0;
            left: 0;
        }
    }

    @include mq(sm) {
        padding: 0 1.1494252874%;
    }
}

